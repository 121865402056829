:root {
    /* colors */
    --main-color-rgb: 27, 97, 162;
    --main-color-rgb: 233, 30, 99;
    --main-color-rgb: 156, 39, 176;
    --main-color-rgb: 97, 166, 96;
    --main-color-rgb: 42, 163, 47;
    --main-color-rgb: 28, 98, 162;
    --main-color: rgba(var(--main-color-rgb), 1);
    --main-color-10: rgba(var(--main-color-rgb), 0.1);
    --main-color-20: rgba(var(--main-color-rgb), 0.2);
    --main-color-30: rgba(var(--main-color-rgb), 0.3);
    --main-color-40: rgba(var(--main-color-rgb), 0.4);
    --main-color-50: rgba(var(--main-color-rgb), 0.5);
    --main-color-60: rgba(var(--main-color-rgb), 0.6);
    --main-color-70: rgba(var(--main-color-rgb), 0.7);
    --main-color-80: rgba(var(--main-color-rgb), 0.8);
    --main-color-90: rgba(var(--main-color-rgb), 0.9);
    --black-rgb: 0, 0, 0;
    --black: rgba(var(--black-rgb), 1);
    --black-90: rgba(var(--black-rgb), 0.9);
    --black-80: rgba(var(--black-rgb), 0.8);
    --black-70: rgba(var(--black-rgb), 0.7);
    --black-60: rgba(var(--black-rgb), 0.6);
    --black-40: rgba(var(--black-rgb), 0.4);
    --black-20: rgba(var(--black-rgb), 0.2);
    --black-10: rgba(var(--black-rgb), 0.1);
    --black-7: rgba(var(--black-rgb), 0.07);
    --black-4: rgba(var(--black-rgb), 0.04);
    --black-3: rgba(var(--black-rgb), 0.03);
    --black-2: rgba(var(--black-rgb), 0.02);
    --white-rgb: 255, 255, 255;
    --white: rgba(var(--white-rgb), 1);
    --white-90: rgba(var(--white-rgb), 0.9);
    --white-80: rgba(var(--white-rgb), 0.8);
    --white-70: rgba(var(--white-rgb), 0.7);
    --white-60: rgba(var(--white-rgb), 0.6);
    --white-40: rgba(var(--white-rgb), 0.4);
    /* containers */
    --container-split-color: #dfe1e6;
    --container-content-padding: 36px;
    /* misc */
    --z-index-backdrop: 5000;
    --z-index-mainheader: 3000;
    --z-index-subheader: 2990;
    --z-index-widgets: 4001;
    --z-index-toast: 5000;
    --row-hover-background: #fafafa;
    --assistant-color-rgb: 87, 57, 190;
    --assistant-color: rgba(var(--assistant-color-rgb), 1);
    --assistant-color-60: rgba(var(--assistant-color-rgb), 0.6);
    --assistant-color-10: rgba(var(--assistant-color-rgb), 0.1);
    --assistant-color-20: rgba(var(--assistant-color-rgb), 0.2);
    /* --shadow-pop-widgets:0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12); */
    --shadow-pop-widgets: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    --shadow: 0px 1px 1px -1px rgba(var(--black-rgb), 0.2),
    0px 1px 1px 0px rgba(var(--black-rgb), 0.14),
    0px 1px 3px 0px rgba(var(--black-rgb), 0.12);

    --validation-color-success: green;
    --validation-color-error: #f44336;
    --validation-background-error: #ffeeee;
    --validation-color-warning: #ff9800;
    --validation-background-warning: #fffedf;
    --validation-color-info: var(--main-color);
    --validation-background-info: var(--main-color-20);

    --paidByCompany-color: #1c62a2;

    --section-reiseroute-background: #eff0f1;
    --section-reiseroute-color: #8e8e8e;
    --section-verpflegungsaufwand-background: #c7e1ce;
    --section-verpflegungsaufwand-color: #50846a;
    --section-uebernachtungskosten-background: #ffcf8f;
    --section-uebernachtungskosten-color: #dd912c;
    --section-fahrtkosten-background: #fcf29b;
    --section-fahrtkosten-color: #d4b200;
    --section-reisenebenkosten-background: #eec8ff;
    --section-reisenebenkosten-color: #ce76fc;
    --section-zahlungen-background: #d8e6f1;
    --section-zahlungen-color: #5198cf;
}

@media (max-width: 767px) {
    :root {
        --container-content-padding: 16px;
    }
}

* {
    box-sizing: border-box;
    outline: none;
}

html {
    height: 100%;
    font-size: 16px;
}

body {
    padding: 0;
    margin: 0;
    height: 100%;
    font-family: 'lato';
    color: var(--black-90);
}

#root {
    height: 100%;
}

header {
    margin-bottom: 2rem;
}

a,
a:link,
a:visited,
a:active {
    color: var(--main-color);
    text-decoration: none;
}

a:hover {
    color: var(--main-color);
    text-decoration: underline;
}

.hidden {
    display: none;
}

.invisible {
    visibility: hidden;
}

.disableHorizontalScrolling {
    overflow-x: hidden;
}

.highlightText {
    padding: 0 0.3rem;
    border-radius: 4px;
    font-weight: 500;
}

.highlightTextDark {
    background-color: var(--black-60);
    color: white;
}

.highlightTextLight {
    background-color: var(--black-10);
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #ddd;
}

::-webkit-scrollbar-thumb {
    background: #666;
}

.icon::before {
    font-family: 'Material Icons';
}
