.iconButton {
    width: 32px;
    height: 32px;
    line-height: 30px;
    font-size: 24px;
    border: 1px solid transparent;
    padding: 0px;
    margin: 0px;
    text-align: center;
    position: relative;
    background: transparent;
    cursor: pointer;
    border-radius: 4px;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.size_s {
    width: 24px;
    height: 24px;
    line-height: 22px;
}

.size_s svg {
    font-size: 18px;
}

.size_m {
    width: 32px;
    height: 32px;
    line-height: 30px;
}

.size_m svg {
    font-size: 24px;
}

.size_ml {
    width: 38px;
    height: 38px;
    line-height: 36px;
}

.size_ml svg {
    font-size: 24px;
}

.size_l {
    width: 44px;
    height: 44px;
    line-height: 42px;
}

.size_l svg {
    font-size: 30px;
}

.iconButton.textInput {
    color: var(--black-40);
}

.iconButton.textInput:hover {
    color: var(--black-80);
}

.iconButton.primary {
    color: var(--black-90);
    background: transparent;
    transition: all 0.25s;
}

.iconButton.assistant {
    color: var(--assistant-color);
    transition: all 0.25s;
    background-color: #fff;
}

.iconButton.assistant:hover {
    background-image: linear-gradient(var(--assistant-color-10), var(--assistant-color-10));
}

.iconButton.secondary {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    background: #f4f5f7;
    transition: all 0.25s;
    color: var(--black-90);
}

.iconButton.tertiary {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    background: var(--black-60);
    color: var(--white-90);
    transition: box-shadow 0.25s, background 0.25s;
}

.iconButton.bright {
    color: var(--white-60);
    background: transparent;
    transition: all 0.25s;
}

.iconButton.mainColor {
    border: 1px solid transparent;
    background: var(--main-color);
    color: var(--white-90);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.iconButton.mainColor:hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.iconButton.mainColor:focus {
    animation-name: none;
}

.iconButton.primary:not([disabled]):hover {
    background: var(--black-4);
}

.iconButton.tertiary.active,
.iconButton.tertiary:not([disabled]):hover {
    background: var(--black-90);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.iconButton.tertiary:focus {
    animation-name: none;
}

.iconButton.secondary:not([disabled]):hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.iconButton[disabled] {
    cursor: default;
}

.iconButton:focus {
    animation-name: buttonClick;
    animation-duration: 0.2s;
    outline: none;
}

@keyframes buttonClick {
    0% {
        background: var(--black-4);
    }
    30% {
        background: var(--black-20);
    }
    100% {
        background: var(--black-4);
    }
}
