.EXPORTED,
.APPROVED {
  color: rgba(52, 169, 61, 1);
}

:global(.global_trip_listing) .EXPORTED,
:global(.global_trip_listing) .APPROVED {
  color: rgba(52, 169, 61, 0.5);
}

.NON_APPROVED,
.FAILED,
.REJECTED {
  color: rgba(255, 0, 0, 1);
}

:global(.global_trip_listing) .NON_APPROVED,
:global(.global_trip_listing) .FAILED,
:global(.global_trip_listing) .REJECTED {
  color: rgba(255, 0, 0, 0.5);
}

.NONE,
.RETRY,
.DRAFT,
.EXPORTING {
  color: var(--black-40);
}

:global(.global_trip_listing) .NONE,
:global(.global_trip_listing) .RETRY,
:global(.global_trip_listing) .DRAFT,
:global(.global_trip_listing) .EXPORTING {
  color: var(--black-20);
}

.WAITING_FOR_APPROVAL {
  color: var(--main-color);
}

:global(.global_trip_listing) .WAITING_FOR_APPROVAL {
  color: var(--main-color-20);
}

.EXPORTING {
  display: inline-block;
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(-360deg);
  }
}
