.container {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: var(--z-index-toast);
  width: 500px;
}

.toastContainer {
  background: #fff;
  border-radius: 4px;
  animation-name: slideIn;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
}

.toastContainer + .toastContainer {
  margin-top: 16px;
}

.icon {
  flex: 0 0 auto;
  line-height: 24px;
  margin-top: 4px;
}

.icon:before {
  font-family: 'material icons';
  font-size: 24px;
}

.SUCCESS {
  color: var(--validation-color-success);
}

.ERROR {
  color: var(--validation-color-error);
}

.WARN {
  color: var(--validation-color-warning);
}

.INFO {
  color: var(--validation-color-info);
}

.message {
  flex: 1 1 auto;
  padding: 6px 16px 0;
}

.spanLink {
  color: var(--main-color);
  font-weight: 700;
  margin-left: 4px;
  cursor: pointer;
}

.spanLink:hover {
  text-decoration: underline;
}

@keyframes slideIn {
  0% {
    opacity: 0.4;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media (max-width: 767px) {
  .container {
    width: calc(100% - 32px);
    max-width: none;
    min-width: 0;
  }
}
